.spinner_overlay{
  background-color: #000;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  opacity: 0.6;
  overflow-y: hidden;
}
.spinner-box {
  position: fixed;
  left: 50%;
  top: 50%;  
  z-index: 12; 
}


.loading-icon {
  display: flex;
  margin: auto;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  position: relative;
}





.error {
  color: red;
  font-size: 14px;
  
}

.upload-input {position: absolute;left: 0;top: 0;width: 100%;height: 100%;opacity: 0;cursor: pointer;}

.image_msg {
  font-size: 14px;
  background-color: #fff3f2;
  color: #f96453;
  border-radius: 4px;
  border: 1px solid #f96453;
  max-width: 90px;
  padding: 5px;
  height: 70px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: 15px !important;
  flex: 0 0 105px;
  margin-right: 8px;
}

.image_msg i {
  display: block;
  font-size: 30px;
  margin-bottom: 6px;
}

.image_msg span {
  position: absolute;
  top: 0px;
  right: 7px;
  font-weight: 700;
  color: #000;
  cursor: pointer;
}

.image_msg label {
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.loading img{
  width: 150px;
  height: auto;
  background-color: #ffffff;
  padding: 10px 21px;
  border-radius: 10px;
}
.active_like{
  background: green;
  border-radius: 50%;
}
.active_dislike{
  background: #ff7070;
  border-radius: 50%;
}

.jiMOeR .wrapper{
  z-index: 9999999999999 !important;
}
.react-tel-input .form-control {
  border: 1px solid #ddd !important;
  width: 100% !important;
  height: 100% !important;
  padding: 11px 14px 11px 60px !important;
}

.react-tel-input .country-list .search-box {
  min-width: 92% !important;
}

.modal-footer{
  padding: 1rem;
}

.modal-body {
  padding: 1rem;
}

.modal-header {
  padding: 1rem;
  padding-top: 26px;
  padding-bottom: 0;
}

.modal-header .modal-title{
  font-weight: 600;
}

.bannersetimg{
  width: 100%;
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bannersetimg img, .scrollimgs img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scrollimgs img{
  border-radius: 10px;
}


.scrollimgs{
  width: 128px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.logout svg {
  width: 80px;
  height: 80px;
  display: block;
  margin: auto;
}
.logout svg path {
  fill: #f44336;
}
.invite_btn a{
padding-left: 20px;
padding-right:20px;
}

.mobilemenubottom a.active {
  color: #7fbfbf;
}

.mobilemenubottom a.active svg path,
.mobilemenubottom a.active svg {
  fill: #7fbfbf;
}
.red_btn{
  background-color: #F10202;
  border-color: #F10202;
}

.red-color{
  color: #F10202;
}

